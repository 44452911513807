import { defineComponent } from 'vue'

export const HeaderPremActiveIcon = defineComponent({
	name: 'HeaderPremActiveIcon',
	setup: () => {
		return () => (
			<svg
				width="10"
				height="10"
				viewBox="0 0 10 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="0.5" y="0.5" width="9" height="9" rx="4.5" fill="#FFB800" />
				<rect x="0.5" y="0.5" width="9" height="9" rx="4.5" stroke="#141517" />
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M5.27615 2.44811C5.174 2.21456 4.8261 2.21456 4.72395 2.44811L4.11316 3.84465C4.07009 3.9431 3.97273 4.01038 3.86095 4.0189L2.27555 4.13977C2.01042 4.15998 1.90291 4.47464 2.10492 4.63919L2.97179 5.34535C3.03047 5.34738 3.10151 5.35018 3.18724 5.35394C3.85966 5.38342 5.226 5.02025 5.226 5.02025C5.39697 4.99724 5.47399 5.21487 5.32253 5.29301C5.32253 5.29301 3.93725 5.68973 3.44586 6.17675C3.392 6.23012 3.34611 6.2748 3.30709 6.31204L3.04012 7.37635C2.9784 7.62239 3.25987 7.81686 3.48686 7.68501L4.84418 6.89661C4.93987 6.84102 5.06022 6.84102 5.15592 6.89661L6.51324 7.68501C6.74023 7.81686 7.02169 7.62239 6.95998 7.37635L6.59094 5.90511C6.56492 5.80139 6.60211 5.69254 6.68727 5.62317L7.89518 4.63919C8.09719 4.47464 7.98968 4.15998 7.72454 4.13977L6.13914 4.0189C6.02737 4.01038 5.93 3.9431 5.88694 3.84465L5.27615 2.44811Z"
					fill="#141517"
				/>
			</svg>
		)
	}
})
