import { defineComponent } from 'vue'

export const StarsIcon = defineComponent({
	name: 'StarsIcon',
	props: {
		height: { type: Number, default: 16 }
	},
	setup: (props) => {
		return () => (
			<svg
				width={(props.height * 17) / 16}
				height={props.height}
				viewBox="0 0 17 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.28227 0.5138C8.99286 -0.171267 8.00714 -0.171266 7.71773 0.513801L5.98718 4.61023C5.86517 4.89904 5.5893 5.09637 5.27261 5.12137L0.780724 5.47591C0.0295237 5.5352 -0.275077 6.45818 0.297259 6.94087L2.75281 9.01179C2.91926 9.01773 3.1208 9.02595 3.36412 9.037C5.26929 9.12347 9.14053 8.05819 9.14053 8.05819C9.62494 7.9907 9.84315 8.62906 9.41403 8.85829C9.41403 8.85829 5.48912 10.022 4.09687 11.4505C3.94414 11.6072 3.81402 11.7384 3.70338 11.8477L2.94696 14.9697C2.7721 15.6914 3.56956 16.2619 4.21269 15.8751L8.05838 13.5625C8.3295 13.3995 8.6705 13.3995 8.94162 13.5625L12.7873 15.8751C13.4304 16.2619 14.2279 15.6914 14.053 14.9697L13.0075 10.6542C12.9337 10.3499 13.0391 10.0306 13.2804 9.82715L16.7027 6.94087C17.2751 6.45818 16.9705 5.5352 16.2193 5.47591L11.7274 5.12137C11.4107 5.09637 11.1348 4.89904 11.0128 4.61023L9.28227 0.5138Z"
					fill="currentColor"
				/>
			</svg>
		)
	}
})
